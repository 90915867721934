<template>
  <vz-form ref="vzform" class="vz-add-claim-form" v-model="isValid">
    <vz-loader :status-loader="loader" />
    <vz-row v-if="isFeedback">
      <vz-switcher
        big
        v-model="customerType"
        :items="customerTypes"
        :use-status="false"
        title="От кого"
        class="mb-10"
      />
    </vz-row>
    <vz-row>
      <vz-col col="6" sm="12" xs="12" class="pr-10">
        <vz-input
          v-model="form.orderNum"
          label="Номер заказа"
          required
          type="number"
          class="mb-15 vz-focus-on-next"
          :max-length="9"
          :rules="[formRules.required]"
          @keydown.enter="focusOnNext"
        />
        <vz-input
          v-model="form.bik"
          label="БИК"
          type="number"
          required
          class="vz-focus-on-next"
          :rules="[formRules.required]"
          @keydown.enter="focusOnNext"
        />
      </vz-col>
      <vz-col col="6" sm="12" xs="12" class="pl-10">
        <vz-input
          v-model="form.email"
          label="E-mail"
          required
          class="mb-15 vz-focus-on-next"
          :rules="[formRules.required, formRules.email]"
          @keydown.enter="focusOnNext"
        />
        <vz-input
          v-model="form.account"
          label="Расчётный счёт"
          required
          type="number"
          :rules="[formRules.required]"
          class="vz-focus-on-next"
          @keydown.enter="focusOnNext"
        />
      </vz-col>
    </vz-row>
    <vz-row>
      <vz-textarea
        v-model="form.description"
        label="Суть претензии"
        required
        :max-length="250"
        :rules="[formRules.required]"
        class="mt-5"
      />
    </vz-row>
    <vz-row>
      <vz-col col="6" sm="12" xs="12" class="pr-10">
        <vz-file-uploader
          v-model="form.scanFiles"
          label="Скан претензии"
          required
          outline
          :file-list="true"
          :max-files="1"
          :size="fileSize"
          :accept="fileTypes"
          @change="onChangeFiles"
        />
        <vz-file-uploader
          v-model="form.docFiles"
          outline
          label="Товарно-сопроводительные документы"
          class="mt-15"
          :file-list="true"
          :max-files="1"
          :size="fileSize"
          :accept="fileTypes"
          @change="onChangeFiles"
        />
        <vz-file-uploader
          v-model="form.photoFiles"
          outline
          multiple
          append-files
          label="Фото груза (до 5 файлов)"
          class="mt-15"
          :file-list="true"
          :max-files="5"
          :size="fileSize"
          :accept="fileTypes"
          @change="onChangeFiles"
        />
      </vz-col>
      <vz-col col="6" sm="12" xs="12" class="pl-10">
        <vz-file-uploader
          v-model="form.actFiles"
          label="Коммерческий акт"
          outline
          :file-list="true"
          :max-files="1"
          :size="fileSize"
          :accept="fileTypes"
          @change="onChangeFiles"
        />
        <vz-file-uploader
          v-model="form.reqFiles"
          class="mt-15"
          label="Реквизиты"
          outline
          :file-list="true"
          :max-files="1"
          :size="fileSize"
          :accept="fileTypes"
          @change="onChangeFiles"
        />
      </vz-col>
    </vz-row>
    <vz-row class="flex flex-center text-caption color-light vz-align-center">
      Тип файла - {{ fileTypes.join(', ') }}, размер не более 10 МБ.
    </vz-row>
    <vz-row class="flex flex-end">
      <vz-button
        big
        variant="primary"
        :disabled="!isValid"
        class="vz-add-claim-form-btn mt-25"
        @click="onClick"
      >
        Создать претензию
      </vz-button>
    </vz-row>
  </vz-form>
</template>
  
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { FileUploadProblems } from '~/types/types'
import { useContractorStore } from '@/stores/personal/contractors'
import { createClaim } from '@/api/document'
import formRules from '@/common/vz-form-rules'
import { getClaimDocuments } from '@/api/contractor'
import { emitter } from '~~/common/event-bus'
import { getNextFocusEl } from '@/common/functions'

const emit = defineEmits(['update', 'hide'])

const props = defineProps({
  isFeedback: {
    type: Boolean,
    default: false
  }
})

const { $showToast } = useNuxtApp()
const { contractor, contractorGuid, claimsMeta } = storeToRefs(useContractorStore())
const { setContractorClaims } = useContractorStore()

const vzform = ref<any>(null)
const isValid = ref(true)
const loader = ref(false)
const form = ref({
  orderNum: '',
  email: '',
  bik: '',
  account: '',
  description: '',
  scanFiles: [] as File[],
  actFiles: [] as File[],
  docFiles: [] as File[],
  reqFiles: [] as File[],
  photoFiles: [] as File[]
})

const customerType = ref('dispatch')
const customerTypes = [
  { title: 'Отправитель', value: 'dispatch' },
  { title: 'Получатель', value: 'destination' },
  { title: 'Плательщик', value: 'payer' },
]

const fileSize = 10485760
const fileTypes = ['png', 'jpg', 'jpeg', 'tiff', 'pdf', 'doc', 'docx', 'bmp', 'gif']

function onChangeFiles(files: File[], uploadProblems: FileUploadProblems): void {
  if (uploadProblems.accept || uploadProblems.maxFiles || uploadProblems.size) {
    $showToast({ 
      variant: 'error',
      title: 'Ошибка добавления файла',
      text: uploadProblems.accept
        ? 'Неверный формат файла'
        : (
            uploadProblems.maxFiles
            ? 'Максимальное количество файлов превышено'
            : 'Максимальный размер файла 10MB'
        )
    })
  }
}

async function onClick(): Promise<void> {
  await vzform.value?.validate()
  if (!isValid.value) {
    return
  }

  const formData = new FormData()
  formData.append('orderNumber', form.value.orderNum)
  formData.append('email', form.value.email)
  formData.append('bic', form.value.bik)
  formData.append('comment', form.value.description)
  formData.append('operatingAccount', form.value.account)
  formData.append('claimScan', form.value.scanFiles[0])

  if (form.value.actFiles.length) {
    formData.append('freightClaim', form.value.actFiles[0])
  }
  if (form.value.docFiles.length) {
    formData.append('docScan', form.value.docFiles[0])
  }
  if (form.value.reqFiles.length) {
    formData.append('attributeScan', form.value.reqFiles[0])
  }
  
  form.value.photoFiles.forEach((file, i) => {
    formData.append('cargoPhoto[]', form.value.photoFiles[i])
  })

  if (props.isFeedback) {
    formData.append('customerType', customerType.value)
  } else {
    formData.append('customerGuid', contractorGuid.value)
  }

  loader.value = true
  const { error, message } = await createClaim(formData)
  loader.value = false

  if (error) {
    $showToast({
      variant: 'error',
      title: 'Уведомление',
      text: error?.opt?.message?.description
    })
    return
  }

  if (!props.isFeedback) {
    const requestData = {
      contractorGuid: contractor.value.guid,
      limit: claimsMeta.value?.limit,
      page: 1
    }
    const data = await getClaimDocuments(requestData)
    data.meta = claimsMeta.value
    await setContractorClaims(data)
    emitter.emit('update:claim-list')
    emit('update')
  }

  emit('hide')
  $showToast({
    variant: 'success',
    title: 'Уведомление',
    text: message || ''
  })
}

function focusOnNext() {
  const allFocusable = document.querySelectorAll('.vz-add-claim-form .vz-focus-on-next input');
  getNextFocusEl(allFocusable, document.activeElement)?.focus()
}
</script>

<style lang="scss" scoped>
.vz-add-claim-form {
  position: relative;

  :deep(.vz-switcher-title) {
    font-size: 16px;
  }

  &-btn {
    padding: 19px 21px;

    :deep(.vz-button-title) {
      font-size: 12px !important;
    }
  }
}
</style>
